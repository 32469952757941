/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const videoCall = createSlice({
  name: 'videoCall',
  initialState: {
    videoCallModal: false,
    videoCallData: null,
  },
  reducers: {
    setVideoCallModal(state, action) {
      state.videoCallModal = action.payload;
    },
    setVideoCallData(state, action) {
      state.videoCallData = action.payload;
    },
  },
});

export const {
  setVideoCallData, setVideoCallModal,
} = videoCall.actions;

export default videoCall.reducer;
